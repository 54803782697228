@import 'themes-vars';
@import 'plugins/plugins';

/* breadcrumb */
.link-breadcrumb {
  font-size: 0.75rem;
  display: flex;
  text-decoration: none;
}

/* card */
.card-header {
  font-weight: 600 !important;
  font-size: 15px;
}

.apexcharts-legend-marker {
  margin-right: 8px !important;
}

/* gradient background */
.bg-gradient-ui {
  background-image: linear-gradient(to right, #4FBBEA, #48CFB2);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar {
  right: 0;
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #4FBBEA;
  border-radius: 8px;
  width: 6px;
  height: 6px;
}
